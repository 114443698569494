import React, {useEffect, useState} from 'react';
import { BlogSection } from '../components';

const BlogsPage = () => {
    const [counter, setCounter] = useState(3);
    const [maxCount, setMaxCount] = useState(0);

    const loadMore = () => {
        counter < maxCount && setCounter(counter + 3);
    }
    const loadLess = () => {
        setCounter(3);
    }
    useEffect(() => {
        window.scrollTo(0,0);
    },[]);
  return (
    <div className='my-20'>
        <div>
            <h2 className='text-4xl font-bold text-center md:text-6xl'>
                Our blog
            </h2>
            <p className='max-w-sm mx-auto my-8 text-center'>
                Find useful tips, updates, and insights focused on pet 
                care and well-being.
            </p>
        </div>
        <div className='flex-wrap justify-center max-w-6xl mx-auto my-8 md:flex'>
            <BlogSection count={counter} setMaxCount={setMaxCount}  />
        </div>
        {counter < maxCount ? (
        <div className='flex'>
            <button className='border-2 border-[#005c7b] text-[#005c7b] 
            rounded mx-auto my-10 w-fit px-14 py-2 font-semibold 
            hover:scale-105'
            onClick={loadMore}>
                Load More
            </button>
        </div>
        ):(
        <div className='flex'>
            <button className='border-2 border-[#005c7b] text-[#005c7b] 
            rounded mx-auto my-10 w-fit px-14 py-2 font-semibold 
            hover:scale-105'
            onClick={loadLess}>
                Load Less
            </button>
        </div>)}
    </div>
  )
}

export default BlogsPage;