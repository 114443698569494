import React from 'react';
import CartRow from '../CartRow/CartRow';

const ShoppingCartPopup = () => {
  return (
    <div className="fixed max-h-[65vh] overflow-y-hidden flex flex-col items-center justify-center text-xl text-black bg-white rounded-lg shadow-[0_0_15px_5px_rgba(0,0,0,0.3)] bottom-20 right-5 md:bottom-40 md:right-20">
        <div className="flex-grow overflow-y-auto">
            <CartRow popup={true} />
        </div>
    </div>
  )
}

export default ShoppingCartPopup