import React, {useEffect, useState} from 'react';
import {collection, query, orderBy, onSnapshot, where} from "firebase/firestore"
import {db} from '../../firebase'
import BlogCard from './BlogCard';
// import WhiskersLeft from '../../images/WhiskersLeft.jpg';
// import WhiskersRight from '../../images/WhiskersRight.jpg';

const BlogSection = ({title, count, currentBlog, setMaxCount}) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const q = query(collection(db, 'blogs'), orderBy('id', 'asc'), where('id', '!=', parseInt(currentBlog)));
    onSnapshot(q, (querySnapshot) => {
      const blogData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      }))
      if (!isNaN(parseInt(currentBlog))){
        const startIndex = parseInt(currentBlog) - 1;
        const endIndex = startIndex + count;
        const loopedBlogs = [];
        for(let i = startIndex; i < endIndex; i++){
          const blogIndex = i % blogData.length;
          loopedBlogs.push(blogData[blogIndex]);
        }
        setBlogs(loopedBlogs);
      } else {
        setMaxCount && setMaxCount(blogData.length);
        setBlogs(blogData);
      }
    })
  },[count, currentBlog, setMaxCount]);

  return (
    <div className={`${title ? 'my-20' : ''}`}>
      {title === true &&
        <div className='flex justify-center space-x-5'>
            <img src={"https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FWhiskersLeft.jpg?alt=media&token=a7a9a078-1623-40c3-bbd2-c4a887a82390"} alt='bg' className='hidden w-12 h-12 -mt-1 lg:block'/>
            <h2 className='text-4xl font-semibold text-center md:text-5xl'>
                Our blog
            </h2>
            <img src={"https://firebasestorage.googleapis.com/v0/b/just-natural-paws.appspot.com/o/Logo%2FWhiskersRight.jpg?alt=media&token=b6a8f756-e1b1-4281-b58c-4e55e8968793"} alt='bg' className='hidden w-12 h-12 -mt-1 lg:block'/>
        </div>}
        <div className='flex-wrap justify-center mx-auto my-8 md:flex'>
          {blogs.map((blogObj, index) => (
            index < count &&
              <BlogCard key={blogObj.id} blog={blogObj.data} />
            ))
          }
        </div>
    </div>
  )
}

export default BlogSection;