import React from 'react';
import { Link } from 'react-router-dom';
import ProductCard from '../ProductCard/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCategory } from '../../reducers/categoryReducer';

const ProductSection = (props) => {
  const dispatch = useDispatch();
  const products = useSelector((state)=> state.products.products);
  const selectedCategory = useSelector((state) => state.category.selectedCategory);
  const handleCategoryChange = (category) => {
    dispatch(setSelectedCategory(category));
  }
  const renderProducts = products.filter(product => product.name.includes(selectedCategory)).slice(0, props.subHeading ? products.length : 3);

  return (
    <div className='mt-20'>
        <h2 className='text-4xl font-semibold text-center md:text-5xl'>
            Shop our treats
        </h2>
        {props.subHeading ? (
          <div className='flex justify-center mx-8'>
            <p className='max-w-sm mx-auto mt-6 text-sm text-center md:text-base'>
              Made with love and loaded with nutrition for your furry family members.
            </p>
          </div>
        ):(
          <div className='flex justify-center mx-8'>
            <p className='max-w-sm mx-auto mt-6 text-sm text-center md:text-base'>
              As one of the fastest growing pet treat companies in North America we ensure our products 
              are full of natural and healthy ingredients!
            </p>
          </div>
        )}
        <div className="flex justify-center mt-8">
          <div className="flex items-center overflow-hidden border border-gray-300 rounded-full">
            <button
              className={`px-6 py-2 font-medium ${
                selectedCategory === "Dog" ? "bg-[#005c7b] text-white" : "bg-white text-gray-800"
              }`}
              onClick={() => handleCategoryChange("Dog")}
            >
              Dog Treats
            </button>
            <button
              className={`px-6 py-2 font-medium ${
                selectedCategory === "Cat" ? "bg-[#005c7b] text-white" : "bg-white text-gray-800"
              }`}
              onClick={() => handleCategoryChange("Cat")}
            >
              Cat Treats
            </button>
          </div>
        </div>
        <div className="flex-wrap justify-center max-w-6xl mx-auto my-8 md:flex">
          {
            renderProducts.map((product, index) =>(
              <ProductCard key={index} data={product} />
            ))
          }
        </div>
        {!props.subHeading &&
        <div className="flex justify-center mt-8">
          <Link
            to={'/shop'}
            className="px-6 py-3 text-white bg-[#005c7b] rounded-full hover:bg-gray-800"
          >
            Shop All {selectedCategory} Treats
          </Link>
        </div>
        }
    </div>
  )
}

export default ProductSection;