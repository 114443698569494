import { combineReducers } from "redux";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import categorySlice from './categoryReducer';

const rootReducer = combineReducers({
    products: productReducer,
    cart: cartReducer,
    category: categorySlice,
});

export default rootReducer;